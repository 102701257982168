.ReportInfoModal
  &__Options
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    padding: 16px
    border: 1px solid $lines
    background-color: $pills
    border-radius: 4px
    &.active
      border-color: $surface-900
    &_Icon
      position: absolute
      top: 50%
      left: 8px
      transform: translateY(-50%)
