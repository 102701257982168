.text-1
  font-size: 32px
.text-2
  font-size: 24px
.text-3
  font-size: 20px
.text-4
  font-size: 16px
.text-5
  font-size: 14px
.text-6
  font-size: 12px

.text-blue
  color: $sol_blue // !important #0070CC
.text-MS-SEC
  color: $MS-SEC // # !importantE5007E
.text-surface-50
  color: $surface-50  !important// #FAFAFA

.text-surface-100
  color: $surface-100 !important // #F5F5F5

.text-surface-200
  color: $surface-200 !important // #EEEEEE

.text-surface-300
  color: $surface-300 !important // #E0E0E0

.text-surface-400
  color: $surface-400 !important // #BDBDBD

.text-surface-500
  color: $surface-500 !important // #9E9E9E

.text-surface-600
  color: $surface-600 !important // #757575

.text-surface-700
  color: $surface-700 !important // #616161

.text-surface-800
  color: $surface-800 !important // #424242

.text-surface-900
  color: $surface-900 !important // #212121

.text-success
  color: $sol_success // #2B8F1A

.text-primary
  color: $primary_text !important //#181818
.text-secondary
  color: $secondary_text !important //#707070
.text-secondary_black
  color: $secondary_black !important //#313131
.text-white
  color: $background //#FFFFFF
.text-blue_primary
  color: $primary //#2C3B7E
.text-lightblue
  color: $lightblue //#09B4E9
.text-completed
  color: $completed //#00AC47
.text-yellow
  color: $yellow //#FFD400
.text-red
  color: $red_text //#D00303

.fw-regular
  font-weight: 400
.fw-medium
  font-weight: 500

@media (max-width: 1800px)
  .container-custom
    max-width: 1400px

@media (max-width: 1600px)
  .container-custom
    max-width: 1200px

@media (max-width: 1440px)
  .text-1
    font-size: 30px
  .text-2
    font-size: 22px
  .text-3
    font-size: 18px
  .text-4
    font-size: 16px
  .text-5
    font-size: 14px
  .text-6
    font-size: 12px

@media (max-width: 1300px)
  .container-custom
    max-width: 1000px

@media (max-width: 992px)
  .text-1
    font-size: 28px
  .text-2
    font-size: 20px
  .text-3
    font-size: 18px
  .text-4
    font-size: 16px
  .text-5
    font-size: 14px
  .text-6
    font-size: 12px

@media (max-width: 576px)
  .text-1
    font-size: 26px
  .text-2
    font-size: 18px
  .text-3
    font-size: 16px
  .text-4
    font-size: 14px
  .text-5
    font-size: 12px
  .text-6
    font-size: 10px
