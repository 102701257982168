.DashboardHeader
  display: flex
  justify-content: space-between
  align-items: center
  position: fixed
  top: 0
  left: 0
  z-index: 99998
  width: 100%
  height: 62px
  border-bottom: 1px solid $pills_dark
  padding: 4px 20px
  background: $background
  // box-shadow: $card_shadow

  &.isMedia
    .DashboardHeader__Nav
      display: none
    .DashboardHeader__Link
      height: max-content
      padding: 10px 16px
      font-weight: 400
      color: $secondary_black
    .DashboardHeader__Menu_Mobile
      display: block
    .DashboardHeader__Menu_Mobile_Icon
      display: block
  &__Account
    display: flex
    justify-content: space-between
    align-items: flex-start
    gap: 0.5rem
    width: max-content
    min-width: 84px
    padding: 0.25rem 0.5rem
    border-radius: 14.5px
    background-color: $surface-200 
    color: $MS-SEC !important
    text-align: start
    &_Container
      position: relative
      width: min-content
      // height: 100%
      cursor: pointer
    &:hover
      background-color: darken($surface-200, 10%)
    &_Show_Menu
      position: absolute
      top: 0
      right: 0
      z-index: 11
      width: 100%
      min-width: 84px
      height: auto
      // padding: 0.25rem 0.5rem
      border-radius: 14.5px
      background-color: $surface-200 
      opacity: 0
      text-align: left
      .DashboardHeader__Account
        border-radius: 14.5px 14.5px 0 0
      &_Items
        padding: 0.25rem 0.5rem
        border-radius: 14.5px
        &:hover
          background-color: darken($surface-200, 10%)
        &_Container
          display: flex
          flex-direction: column
          gap: 0.25rem
          padding: 0.5rem

  &__User_Menu
    width: calc(100% + 2px)
    height: fit-content
    border-radius: 0 0 10px 10px
    padding: 0 10px 10px
    background-color: $background
    border: 1px solid $lines
    border-top: unset
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    gap: 10px
    position: absolute
    top: 100%
    right: -1px
    z-index: -1
    &-Detail
      width: 100%
      height: 2px
      background-color: $lines
    &-Item
      width: 100%
      height: 100%
      display: flex
      justify-content: flex-start
      align-items: center
      color: $primary_text
      &:hover
        opacity: .8
  &__Logo
    height: 40px
  &__Nav
    display: flex
    align-items: center
    gap: 8px
  &__Link
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: max-content
    height: 100%
    border-radius: 10px
    padding: 0 10px
    color: $surface-800
    &:hover
      color: $MS-SEC
      &::after
        transform: scaleX(1)
    &::after
      content: ''
      width: 100%
      height: 1px
      background-color: $surface-800
      position: absolute
      bottom: -2px
      left: 0
      z-index: -1
      transform: scaleX(0)
      transform-origin: center
      transition: transform .2s ease
    &--Active
      @extend .DashboardHeader__Link
      color: $MS-SEC
      &::after
        transform: scaleX(1)
    &.Active
      color: $MS-SEC
      &::after
        transform: scaleX(1)
  &__Menu_Mobile
    position: fixed
    top: 0
    z-index: 999
    width: 100vw
    height: 100%
    padding-top: 48px
    background-color: $background
    box-shadow: $card_shadow
    overflow: hidden
    &_Icon
      display: none
      cursor: pointer
    &_Content
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: flex-start
      // gap: 16px
      width: 100%
      height: 100%
      overflow-y: auto
    &_Nav
      flex-grow: 1
      display: flex
      flex-direction: column
      align-items: center
      gap: 10px
      width: 100%
      padding: 10px
    &_User_info
      display: flex
      flex-direction: column
      align-items: center
      // gap: 16px
      width: 100%
      padding: 10px
      &__BB
        @extend .DashboardHeader__Menu_Mobile_User_info
        border-bottom: 1px solid $lines
      &__BT
        @extend .DashboardHeader__Menu_Mobile_User_info
        border-top: 1px solid $lines
      &_Name
        padding: 10px 16px
        font-weight: 500
      &_Button
        display: flex
        align-items: center
        gap: 0.5rem
        padding: 10px 16px
        border-radius: 10px
        font-weight: 400
        color: $secondary_black
        cursor: pointer
        &:hover
          background-color: $hover

.DashboardHeader__Menu_Mobile.enter
  max-height: 0
  .DashboardHeader__Menu_Mobile_Content
    opacity: 0
.DashboardHeader__Menu_Mobile.enter-active
  max-height: 100%
  transition: max-height 300ms
  .DashboardHeader__Menu_Mobile_Content
    opacity: 1
    transition: opacity 100ms 300ms
.DashboardHeader__Menu_Mobile.enter-done
  max-height: 100%
  .DashboardHeader__Menu_Mobile_Content
    opacity: 1
.DashboardHeader__Menu_Mobile.exit
  max-height: 100%
  .DashboardHeader__Menu_Mobile_Content
    opacity: 1
.DashboardHeader__Menu_Mobile.exit-active
  max-height: 0
  transition: max-height 100ms 300ms
  .DashboardHeader__Menu_Mobile_Content
    opacity: 0
    transition: opacity 300ms
