.GridModals
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 120px), 1fr))
  @include Scroll_MS-SEC

.NewModelGrid
  width: 100%
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-auto-flow: column
  gap: 16px 6px
  align-items: baseline
  overflow: hidden auto
  @include Scroll_MS-SEC
  &--One
    @extend .NewModelGrid
    grid-template-rows: repeat(5, 1fr)
  &--Two
    @extend .NewModelGrid
    grid-template-rows: repeat(4, 1fr)


.HotWaterDemand_Grid
  width: 100%
  display: grid
  gap: 8px
  grid-template-areas: "inp inp inp inp inp inp per" "inp inp inp inp inp inp per" "inp inp inp inp inp inp per" "inp inp inp inp inp inp per"
  @include Scroll_MS-SEC
  &_Percentages
    grid-area: per
    display: grid
    grid-template-rows: repeat(4, minmax(0, 1fr))
    align-items: flex-end
    gap: 8px

@media (max-width: 575px)
  .HotWaterDemand_Grid
    grid-template-areas: "inp inp inp inp per" "inp inp inp inp per" "inp inp inp inp per" "inp inp inp inp per" "inp inp inp inp per" "inp inp inp inp per"
    &_Percentages
      grid-template-rows: repeat(6, minmax(0, 1fr))
