// general colors
$background: #FFFFFF
$disabled: #F2F2F2
$pills: #F8F8F8
$pills_dark: #e6e4e4
$lines: #E5E5E5
$lines_dark: #dfdfdf
$yellow: #FFD400
$yellow_dark: #d9b502
$lightblue: #09B4E9
$lightblue_dark: #089dca
$primary_text: #181818
$secondary_text: #707070
$secondary_black: #313131
$input_text: #434343
$input_black: #3d3d3d
$white_text: #FFFFFF
$primary: #2C3B7E
$primary_dark: #1F2958
$ter_one: #715AFF
$ter_two: #A682FF

$bg_primary: #e9ebf2
$hover: #5675870f
$hover_dark: #2e3e471e
$hover_purple: #EAE7FB

$red_text: #D00303

// status colors
$completed: #00AC47
$running: #9747FF
$waiting: #00B3E8
$edited: #FFD300
$ready: #4ad101
$incomplete: #FF0000
$creating: #FF620A

// input status colors
$warning: #FFD4004D
$error: #FF00004D
$edit: #09B5E94D

// shadows
$card_shadow: 2px 2px 15px rgba(62, 62, 62, 0.08)

// New colors 
$sol_blue: #0070CC
$sol_blue10: #E5F0FA
$sol_blue20: #CCE2F5
$sol_blue80: #338DD6
$sol_green: #AEC90B
$sol_green30: #E7EFB5
$sol_success: #2B8F1A
$red: #D00303
$red10: #FAE5E5
$red80: #D93535
$sol_placeholder: #535353

$surface-50: #FAFAFA
$surface-100: #F5F5F5
$surface-200: #EEEEEE
$surface-300: #E0E0E0
$surface-400: #BDBDBD
$surface-500: #9E9E9E
$surface-600: #757575
$surface-700: #616161
$surface-800: #424242
$surface-900: #212121
$MS-SEC: #E5007E
$pink-100: #FAD3E7
$pink-500: #EC4899
