.RunningSimulation
  width: 100%
  min-height: 100%
  padding: 30px 16px 16px
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr
  justify-items: center
  align-items: center
  display: flex
  justify-content: center
  align-items: center
  &__Logo
    height: 50px
    max-width: 100%
  &__Error
    height: 40px
  &__Error-Container
    width: 100%
    height: 300px
    border: 1px solid $error
    padding: 10px
    resize: none

@media (max-width: 767px)
  .RunningSimulation
    grid-template-rows: 1fr
