.SimulationStepsMobile
  grid-column: 2/3
  grid-row: 1/2
  display: flex
  flex-direction: column
  // width: 100%
  height: 100%
  background-color: $background
  overflow: hidden
  &__Current_Step
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center
    gap: 0.5rem
    padding: 0.5rem 1rem
    border-bottom: 1px solid $lines
  &__Steps
    display: flex
    justify-content: space-around
    align-items: center
    height: 30px
    padding: 0.5rem 1rem
    border-bottom: 1px solid $lines
    &_Circle
      position: relative
      width: 6px
      height: 6px
      border-radius: 6px
      background: $surface-700
      &::before
        position: absolute
        top: 2px
        left: 2px
        content: ''
        width: 2px
        height: 2px
        border-radius: 2px
        background: $background
        transform: scale(0)
      &.Group
        width: 12px
        height: 12px
        border-radius: 12px
        &::before
          top: 4px
          left: 4px
          content: ''
          width: 4px
          height: 4px
          border-radius: 4px
      &.Green
        background-color: $MS-SEC
      &.Yellow
        background-color: $edited
      &.Current
        &::before
          transform: scale(1)
