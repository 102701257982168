@mixin scrollbar($width: 12px, $border-width: 3px)
  &::-webkit-scrollbar
    width: $width
    &-thumb
      border: $border-width solid transparent
      border-radius: 10px
      background-clip: content-box
      background-color: $MS-SEC
      &:hover
        background-color: lighten($MS-SEC, 10%)
        cursor: pointer
    &-track
      background-color: $lines
*
  box-sizing: border-box
  font-family: Roboto, sans-serif

html
  overflow-x: hidden

body
  height: 100vh
  height: 100dvh
  margin: 0
  border: 0
  padding: 0
  font-family: 'Roboto', sans-serif
  color: $primary_text
  font-size: 16px
  font-weight: 400
  scroll-behavior: smooth
  position: relative
  overflow-x: hidden
  @include scrollbar(12px)

main
  height: 100vh
  height: 100dvh

.dashboard-main
  width: 100%
  height: 100vh
  height: 100dvh
  padding-top: 62px
  @include scrollbar(10px)

p
  margin-bottom: 0
  color: $primary_text
  // line-height: 1

a
  color: inherit
  text-decoration: none
  &:hover
    color: unset

button
  border: unset
  padding: unset
  background: unset
  cursor: pointer

button:disabled
  cursor: not-allowed

a, button
  transition: border .2s, background-color .2s, color .2s, opacity .2s

h1, h2, h3, h4, h5, h6
  font-weight: bold
  margin-bottom: unset

input, textarea
  border: unset
  background: unset
  &:focus
    outline: none

.container-custom
  max-width: 1600px
  margin: 0 auto

.link
  color: $sol_blue
  &:hover
    color: $sol_blue80

.bg-transparent_red
  background-color: rgba(red, 30%)

.Checkbox
  flex-shrink: 0
  width: 16px
  height: 16px
  border-radius: 6px
  border: 2px solid $primary_text
  position: relative
  cursor: pointer
  &::after
    content: ''
    width: 8px
    height: 8px
    border-radius: 3px
    background-color: $primary_text
    position: absolute
    top: 2px
    left: 2px
    transform: scale(0)
    transition: transform 200ms
  &--Selected
    @extend .Checkbox
    cursor: default
    &::after
      transform: scale(1)
  &.Selected
    &::after
      transform: scale(1)
  &.Small
    width: 12px
    height: 12px
    border-radius: 4px
    &::after
      top: 1px
      left: 1px
      width: 6px
      height: 6px
      border-radius: 2px
  &.Color-secondary
    border-color: $secondary_text
    &::after
      background-color: $secondary_text
  &.Color-blue
    border-color: $sol_blue
    &::after
      background-color: $sol_blue

.Radio
  @extend .Checkbox
  border-radius: 8px
  &.Show
    cursor: default
  &::after
    @extend .Checkbox, ::after
    border-radius: 4px
  &.Selected
    cursor: default
    border-radius: 8px
    &.blue
      border-color: $primary
      &::after
        background-color: $primary
        border-color: $primary
    &::after
      transform: scale(0.75)
      border-radius: 5px
  &.Complete
    cursor: default
    border-radius: 8px
    &::after
      transform: scale(1.75)
      border-radius: 8px

@mixin Scroll
  &::-webkit-scrollbar
    width: 10px
    height: 10px
    &-thumb
      border: 1px solid transparent
      border-radius: 10px
      background-clip: content-box
      background-color: $secondary_text
      &:hover
        cursor: pointer
    &-track
      background-color: $background

@mixin Scroll_Blue
  &::-webkit-scrollbar
    width: 6px
    &-thumb
      border-left: 1px solid transparent
      border-radius: 10px
      background-clip: content-box
      background-color: $sol_blue
      &:hover
        background-color: $sol_blue80
        cursor: pointer
    &-track
      background-color: $lines

@mixin Scroll_MS-SEC
  &::-webkit-scrollbar
    width: 6px
    &-thumb
      border-left: 1px solid transparent
      border-radius: 10px
      background-clip: content-box
      background-color: $MS-SEC
      &:hover
        background-color: lighten($MS-SEC, 10%)
        cursor: pointer
    &-track
      background-color: $lines

@mixin Scroll_Gray
  &::-webkit-scrollbar
    width: 10px
    height: 10px
    &-thumb
      border: 1px solid transparent
      border-radius: 10px
      background-clip: content-box
      background-color: $lines_dark
      &:hover
        cursor: pointer
    &-track
      background-color: $background

.Scroll
  @include Scroll

.Scroll_MS-SEC
  @include Scroll_MS-SEC

.Scroll_blue
  @include Scroll_MS-SEC

.Scroll_gray
  @include Scroll_Gray

.Animation_Opacity.enter
  opacity: 0
.Animation_Opacity.enter-active
  opacity: 1
  transition: opacity 200ms
.Animation_Opacity.enter-done
  opacity: 1
.Animation_Opacity.exit
  opacity: 1
.Animation_Opacity.exit-active
  opacity: 0
  transition: opacity 200ms
