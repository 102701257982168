.MaktBanner
  display: flex
  justify-content: center
  align-items: center
  background-color: $disabled
  width: 100%
  height: 100%
  &__Logo
    width: 100%
    height: 100%
    object-fit: cover
