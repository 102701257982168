.Modal
  &__Container
    width: 100%
    height: 100vh
    padding: 0 16px
    background: rgba(0, 0, 0, .8)
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    top: 0
    left: 0
    z-index: 99999
  &__Card
    width: 100%
    max-width: 540px
    height: fit-content
    max-height: 95vh
    border-radius: 10px
    padding: 20px
    background: $background
    overflow: hidden
    &--Big
      @extend .Modal__Card
      max-width: 966px
      max-height: 95vh
  &__Header
    display: grid
    grid-template-columns: 30px 1fr 30px
    align-items: center
    width: 100%
    padding-bottom: 8px
  &__Button
    width: 30px
    height: 30px
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    font-size: 18px
    &:hover
      background: $lines
  &__Divider
    flex-shrink: 0
    width: 100%
    height: 1px
    border-radius: 3px
    background-color: $lines
    &--MS-SEC
      @extend .Modal__Divider
      height: 2px
      background-color: $MS-SEC
  &__Scroll-Content
    height: 100%
    max-height: 80vh
    padding-right: 12px
    overflow: hidden auto
    @include Scroll_MS-SEC
    .NewModelGrid--One
      overflow: unset
  &__Error
    height: 40px
    // &::-webkit-scrollbar
    //   width: 6px
    //   &-thumb
    //     border: 1px solid transparent
    //     border-radius: 10px
    //     background-clip: content-box
    //     background-color: $lightblue
    //     box-shadow: inset 0 0 5px rgba(0, 0, 0, .5)
    //     &:hover
    //       background-color: $lightblue_dark
    //       cursor: pointer
    //   &-track
    //     background-color: $lines

@media (max-width: 767px)
  .Modal
    &__Card
      max-width: 412px
