.Switch
  flex-shrink: 0
  position: relative
  width: 40px
  height: 20px
  border-radius: 20px
  background-color: $surface-300
  transition: background-color 200ms
  &::after
    content: ''
    position: absolute
    top: -1px
    left: -1px
    width: 22px
    height: 22px
    border-radius: 22px
    background-color: $surface-600
    transition: left 200ms, background-color 200ms
  &.active
    background-color: #ABC9FB
    &::after
      left: 19px
      background-color: #326FD1
  &.Small
    width: 32px
    height: 16px
    &::after
      width: 18px
      height: 18px
    &.active
      &::after
        left: 15px
  &.Pink
    &.active
      background-color: $pink-100
      &::after
        background-color: $pink-500
