.SimulationSteps
  grid-column: 1/2
  grid-row: 2/3
  width: 100%
  height: 100%
  background: $background
  display: flex
  flex-direction: column
  overflow: hidden auto
  :last-child
    .SimulationSteps__Circle
      &::after
        content: unset !important
  @include Scroll_MS-SEC
  &__Link
    width: 100%
    height: 25px
    padding: 5px 15px 5px 45px
    display: flex
    align-items: center
    position: relative
    &:hover
      background-color: $bg_primary
    &.main
      height: 40px
      padding: 10px 15px 10px 45px
      .SimulationSteps__Circle
        width: 14px
        height: 14px
        top: 13px
        left: 17px
        &::before
          width: 4px
          height: 4px
          top: 5px
          left: 5px
        &::after
          left: 6px
    &.active
      background-color: $surface-100
      .SimulationSteps__Circle
        background: $completed
        &::after
          content: ''
        &::before
          content: ''
      .SimulationSteps__Detail
        display: block
    &.completed
      // border-left: 3px solid $primary
      background-color: $surface-200
      &:hover
        background-color: $surface-100
      .SimulationSteps__Circle
        background: $MS-SEC
        &::after
          background: $MS-SEC
    &.edited
      &:hover
        background-color: $background
      .SimulationSteps__Circle
        background: $edited
        &::after
          background: $edited
    &.edited_active
      &:hover
        background-color: $background
      .SimulationSteps__Circle
        background: $edited
        &::after
          content: ''
    &.disabled
      &:hover
        background-color: $background
      .SimulationSteps__Circle
        background: $surface-700
        &::after
          background: $secondary_text
  &__Circle
    width: 9px
    height: 9px
    border-radius: 50%
    background: $secondary_text
    position: absolute
    top: 8px
    left: 19px
    z-index: 2
    &::after
      content: ''
      width: 2px
      height: 24px
      background: $secondary_text
      position: absolute
      top: 100%
      left: 4px
      z-index: 1
    &::before
      width: 3px
      height: 3px
      border-radius: 50%
      background: $background
      position: absolute
      top: 3px
      left: 3px
  &__Detail
    display: none
    width: 3px
    height: 100%
    background-color: $MS-SEC
    position: absolute
    top: 0
    left: 0
